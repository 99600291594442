@import "variables.scss";
@import "breakpoints";

:root {
  --appbar-height: 70px;
}

@media (min-width: 768px) {
  :root {
    --appbar-height: 80px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: $font-family-gibson;
}

html,
body {
  scroll-behavior: smooth;
  font-family: $font-family-gibson;
  font-size: 16px;
  color: $text-color;
  background: $scaffold-color;
  max-width: 100vw;
  overflow-x: hidden;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
}

button {
  background: $color-primary;
  border: none;
  cursor: pointer;
  color: $color-secondary;
  font-size: 12px;
  font-weight: $font-weight-gibson-bold;
  font-family: $font-family-gibson;
  letter-spacing: 0.24px;
  line-height: 0;
  text-transform: uppercase;
  @include medium {
    letter-spacing: 0.28px;
    font-size: 14px;
  }
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.textLink {
  text-decoration: underline;
}

.no-scroll {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}
